.root {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 100px;
}



.layout__content {
    width: 100%;
    flex: 1 0 auto;
    display: block;
    background: rgba(255, 255, 255, 0);
    color: var(--white);
    padding-top: 100px;
    font-size: 16px;
}

.secondary-title {
    color: var(--green-bright);
    font-size: 1em;
    padding: 0;
    margin: 0;
}

.primary-title {
    font-size: 3em;
    margin: 0;
    padding: 0;
}

@media (max-width: 600px) {
    .main-article-conten {
        max-width: 300px; //TODO refactor shame css
        padding: 10px;
    }
}

.main-article-content {
    max-width: 400px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.navigation-button {
    margin-right: 10px;
    margin-left: 10px;
    padding: 10px 20px;
    border: 1px solid var(--green-dark);
    border-radius: 6px;
    text-decoration: none;
    color: var(--green-middle);
    font-size: 18px;
}
.navigation-button:hover{
    color: var(--green-bright);
    border: 1px solid var(--green-middle);
}

.buttons-wrapper {
    padding-top: 20px;
    margin-bottom: 30px;
    padding-bottom: 10px;
}
