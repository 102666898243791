.root {}


.footer-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.logo-footer {
    align-self: center;
    font-size: 1rem;
}

.secondary-nav {
    text-decoration: none;
    margin: 0 5px;
    color: var(--green-middle);
    font-size: 13px;
}

.secondary-nav:hover {
    color: var(--green-bright);
}
