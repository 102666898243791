@media (max-width: 600px) {
  .icon {
    display: none !important; //TODO refactor shame css
  }
}

.icon {
  margin-right: 7px;
}


.main-nav {
  padding: 0 30px 0 0;
  color: var(--green-middle);
}

.main-nav-link {
  margin: 0 0 0 20px;
  color: inherit;
  text-decoration: none;
  font-size: 14px;
  align-self: baseline;
}

.main-nav-link:hover {
  color: var(--green-bright);
}

.active {
  color: var(--green-dark);
}
