.root {
    background-color: var(--black);
    height: 120px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.logo {
    padding-left: 15px;
    display: flex;
    align-self: stretch;
    flex-direction: row;
}

#logo-png {
    height: 25px;
}


.logo-link {
    margin-top: auto;
    margin-bottom: auto;
    align-self: baseline;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-middle-area {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.logo-text {
    color: var(--blue-logo);
    padding-left: 20px;
    font-size: 1.5rem;
    font-weight: 400;
}

hi.logo-text>h1 {
    padding: 0;
}



.header {
    border-bottom: 4px solid #1D1D29;
    background: rgba(7, 9, 10, 0);
}

.logo-png {
    width: 50px;
    height: 50px;
}
