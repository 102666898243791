body {
    height: 100vh;
}

#root {
    height: 100%;
}

:root {
    --black: #07090a;
    --white: #fff;
    --blue-logo: #126bee;
    --blue-middle: #2156a4;
    --blue-dark: #2a456d;
    --blue-pale: #202b3b;
    --green-dark: #226a5c;
    --green-middle: #149f83;
    --green-bright: #00ffcd;
    --green-pale: #1d3934;
    --violet-dark: #392f73;
    --violet-normal: #3d29ac;
    --violet-bright: #3a1af0;
    --violet-pale: #27233e;
}

.App {
    text-align: center;
    background: var(--black);
    display: block;
    height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--white);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.layout {
    min-width: 340px;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 1024px;
}

.wrapper {
    width: 100%;
    background-color: transparent;
    display: flex;
    height: 100%;
    background-image: linear-gradient(134deg, #07090a 0%, #130F29 72%, #07090a 100%);
    background-position: 0 0;
    background-size: auto auto;
    background-repeat: repeat;
    background-attachment: scroll;
    background-origin: padding-box;
    background-clip: border-box;
}

.footer {
    flex-shrink: 0;
    height: 100px;
    display: flex;
    align-items: baseline;
    align-content: space-evenly;
    justify-content: space-between;
    border: 0 solid #292D3A;
    border-top-width: 1px;
}
