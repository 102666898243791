.root {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 100px;
}



.layout__content {
    width: 100%;
    flex: 1 0 auto;
    display: block;
    background: rgba(255, 255, 255, 0);
    color: var(--white);
    padding-top: 100px;
    font-size: 16px;
}



.primary-title {
    font-size: 3em;
    margin: 0;
    padding: 0;
}

.main-article-content {
    width: 400px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
